import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import mainImage from "./assets/main.jpeg";
import logoImage from "./assets/logo_white.png"
import main1Image from "./assets/main1.jpeg"

import FavoriteIcon from "@mui/icons-material/Favorite";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import SpaIcon from "@mui/icons-material/Spa";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

function App() {
  const data = [
    {
      icon: (
        <FavoriteIcon
          style={{
            fontSize: 50,
            fontFamily: "NeueMachina-Regular",
            color: "#bc1822",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
      ),
      name: "CARDIO ZONE",
      desc: "Choose from a wide range of regimes that fits your bucket.",
    },
    {
      icon: (
        <FitnessCenterIcon
          style={{
            fontSize: 50,
            fontFamily: "NeueMachina-Regular",
            color: "#bc1822",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
      ),
      name: "WEIGHTS ZONE",
      desc: "Build your strength and endurance from the ground up with progressive overloading. Push yourself out of the comfort zone.",
    },
    {
      icon: (
        <SelfImprovementIcon
          style={{
            fontSize: 50,
            fontFamily: "NeueMachina-Regular",
            color: "#bc1822",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
      ),
      name: "YOGA PARK",
      desc: "Practice in tranquility. Explore and awaken your senses as you feel at home in our nature park.",
    },
    {
      icon: (
        <SpaIcon
          style={{
            fontSize: 50,
            color: "#bc1822",
            marginRight: "10px",
            marginBottom: "10px",
          }}
        />
      ),
      name: "WELLNESS CENTRE",
      desc: "Get customized wellness plan based on your unique health profile. Our expert doctors, physiotherapists and dieticians curate a hollistic wellness plan for your fitness.",
    },
  ];
  return (
    <div>
      <section id="main">
        <div className="container">
          <div className="main">
            <div className="main-text">
              <div className="logo-test d-flex">
                <img
                  src={logoImage} width="200" 
                  height="200" alt=""
                />
                <p>
                  GYMKHANA <br />
                  FITNESS <br />
                  360
                </p>
              </div>
              <div className="bio">
                <p>
                  ITS TIME TO <br />
                  BE HEALTHY <br />
                  AND IN <br />
                  GREAT SHAPE
                </p>
              </div>
              <div className="signup">
                <button>SIGN UP NOW</button>
              </div>
            </div>
            <div className="main-image">
              <img
                style={{ width: "100%", height: "100%", position: "absolute" }}
                src={mainImage}
                alt="Girl in a jacket"
              ></img>{" "}
              <p className="txt">
                We offer classes and personal training designed to help you get
                fit
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="lean">
        <div className="container">
          <div className="main">
            <div className="main-image1">
              <img
                style={{ width: "80%", height: "100%" }}
                src={main1Image}
                alt="Girl in a jacket"
              ></img>
            </div>
            <div className="main-text">
              <div className="txt1">
                <p>
                  GYMKHANA IS ON A MISSION
                  <br />
                  TO HELP YOU ACHEIVE YOUR FITNESS GOALS AND LIVE A HEALTHY
                  LIFESTYLE
                </p>
              </div>

              <div className="learnmoretxt">
                <button>LEARN MORE</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="amenities">
        <div className="container">
          <div className="amenities-div">
            <div className="amenities-text">
              OUR <br /> AMENITIES
            </div>

            <div className="dataContainer">
              {data?.map((d) => (
                <div key={d.name} className="card1">
                  {d.icon}
                  <h4 style={{ color: "#bc1822" }}>{d.name}</h4>
                  <p style={{ color: "#909090" }}>{d.desc}</p>
                </div>
              ))}
            </div>

            <div className="dataContainer">
              {data?.map((d) => (
                <div key={d.name} className="card1">
                  <div className="signup">
                    <button style={{ width: "100%", marginBottom: "20px" }}>
                      Get Started
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="footer-logo">
          <img
            style={{ width: "50px", height: "50px" }}
            src={logoImage} alt=""
          />
          <p
            style={{
              marginLeft: "10px",
              color: "black",
              fontSize: "12px",
              fontFamily: "NeueMachina-Regular",
              marginBottom: 0,
            }}
          >
            GYMKHANA <br />
            FITNESS <br />
            360
          </p>
        </div>
        <footer className="page-footer font-small blue mt-5">
          <div className="container-fluid text-md-left">
            <div className="row">
              <div className="col-md-4 mt-md-0 mt-3 mb-5">
                <h5 className="text-uppercase">BANSBERIA</h5>
                <span>Khamarpara Bakul Tala Lane</span>
                <span> </span>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>8073766549</span>
                  <span>mailgymkhana@gmail.com</span>
                </div>
              </div>

              {/* <hr className="clearfix w-100 d-md-none pb-3"> */}

              <div className="col-md-4 mb-md-0 mb-3 mb-5">
                <h5 className="text-uppercase">CLUB HOURS</h5>

                <span>Monday to Sunday</span>
                <span> 6:00 AM to 10:00 PM</span>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>FACILITIES</span>
                  <span>AC, Unisex, Multigym, and Yoga</span>
                  <span style={{ marginTop: "20px" }}>Nutrition and Health counselors</span>
                </div>
              </div>

              <div className="col-md-4 mb-md-0 mb-5">
                <h5 className="text-uppercase">STAY CONNECTED</h5>
                <div className="d-flex">
                  {" "}
                  <FacebookIcon
                    style={{
                      fontSize: 30,
                      fontFamily: "NeueMachina-Regular",
                      marginRight: "10px",
                    }}
                  />
                  <TwitterIcon
                    style={{
                      fontSize: 30,
                      fontFamily: "NeueMachina-Regular",
                      marginRight: "10px",
                    }}
                  />
                  <InstagramIcon
                    style={{
                      fontSize: 30,
                      fontFamily: "NeueMachina-Regular",
                      marginRight: "10px",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </div>
  );
}

export default App;
